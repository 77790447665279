import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Heading, Text, Flex, Box } from '@chakra-ui/react';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import MEDIA from 'helpers/mediaTemplates';
import { maxContainerWidth, accentLight } from '../constants/theme';

const HeroSection = ({
  children,
  background = 'transparent',
  borderTop = 'none',
  title,
  parrafoGrande,
  parrafoPequeno,
  contentHeight = 'auto',
  header = false,
  headerImg,
  headerImgMovil,
}) => {
  // const imgSources = header
  //   ? [
  //       headerImgMovil,
  //       {
  //         ...headerImg,
  //         media: '(min-width: 768px)',
  //       },
  //     ]
  //   : [];

  const headerImages = 
    header && headerImg && headerImgMovil ?
      withArtDirection(headerImg, [
        {
          media: '(max-width: 768px)',
          image: headerImgMovil,
        },
      ]) : headerImg
  
  return <>
  <section style={{ background: background, borderTop: borderTop, transform: 'translateX(0 %) translateZ(0px)'}} >
      <Content style={{ minHeight: contentHeight }}>
        <Flex justifyContent="flex-end">
          {title ? (
            <Heading
              as="h2"
              size="lg"
              textAlign="center"
              className="pageHeader"
            >
              {title}
            </Heading>
          ) : (
            <Box pt="0em" />
          )}
        </Flex>

        {header && headerImg && headerImgMovil && (
          <GatsbyImage image={headerImages} alt={title} className="headerImg" loading="eager" />
          // TODO: probar cambiar a staticImage o no se puede?
        )}

        <Flex
          maxWidth="1024px"
          m={header ? '3em auto 1em' : ['1em auto 0em', '1em auto 0em']}
          minHeight={header ? 'auto' : 'auto'} //77vh
          justifyContent="space-around"
          flexDirection="column"
        >
          {parrafoGrande && (
            <div style={{transform: 'translateX("0%") translateZ("0px")'}}>
              <Text
                as={header ? 'h1' : 'h2'}
                fontSize="2xl"
                className={`parrafo parrafoGrande ${
                  header ? 'headerTitle' : ''
                }`}
                textAlign="center"
                pb="1.5em"
                //position="relative"
              >
                {parrafoGrande}
              </Text>
            </div>
          )}

          {children}

          {parrafoPequeno && (
              <Text fontSize="2xl" className="parrafo" textAlign="center">
                {parrafoPequeno}
              </Text>
          )}
        </Flex>
      </Content>
    </section>  
  </>;
};
HeroSection.propTypes = {
  children: PropTypes.any,
  props: PropTypes.any,
  background: PropTypes.string,
  borderTop: PropTypes.string,
  contentHeight: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  parrafoGrande: PropTypes.any,
  parrafoPequeno: PropTypes.any,
  headerImg: PropTypes.object,
  headerImgMovil: PropTypes.object,
  header: PropTypes.bool,
};

const Content = styled.div`
  .pageHeader {
    color: #fff;
    background: ${accentLight};
    font-size: 1rem;
    font-size: calc(0.5em + 0.8vw);
    line-height: 38px;
    font-weight: bold;
    padding: 0;
    text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.4);
    width: auto;
    border-radius: 0 0 0 12px;
    display: inline-block;
    padding: 6px 16px 0px 12px;
    margin-top: -8px;
    z-index: 2;
  }
  b,
  strong {
    font-weight: 700;
  }
  .headerImg {
    margin-top: -42px;
    min-height: 66vh;
  }
  .contenido {
    max-width: ${maxContainerWidth};
    margin: 0 auto;
  }
  .parrafo {
    margin-bottom: 1.2em;
    font-size: 21px;
    /* color: #485963;//#222; */
    /* margin: 0 auto 1.5em; */
    margin: 1em auto 2em;
    padding-left: 1em;
    padding-right: 1em;
    font-weight: 300;
    font-size: calc(1.3em + 0.2vw);//calc(1.3em + 0.2vw);
    line-height: 1.2;
    text-align: left;
    /* max-width: 30em; */
    max-width: 900px; //60em;
  }
  .parrafoGrande {
    font-size: 37px;
    font-size: calc(0.975rem + 1.6vw);
    font-size: calc(0.6rem + 1.2vw);
    line-height: 1.2;
    font-weight: bold;
    padding: 0;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    max-width: 50em;
    padding-top: 1em;
    /* text-align: center; */
  }
  .headerTitle {
    margin-top: -55vh;
    background: rgb(255 255 255 / 46%);
    backdrop-filter: blur(12px);
    border-radius: 12px;
    border: 1px solid black;
    box-shadow: -3px 6px 11px #333333c4;
    padding: 0.4em 0.6em 0.5em;
    font-size: 1.5em;
    line-height: 1.2;
    font-size: calc(1.625rem + 1.6vw);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    max-width: 1440px;
    text-align: center;
    letter-spacing: -0.03rem;
    position: relative;
  }
  @media (max-width: 1024px) {
    .parrafoGrande {
      padding: 0.7em 1em;
    }
  }
  ${MEDIA.TABLET`
    .parrafoGrande {
      font-size: 1.6rem;
      font-size: calc(1.2325rem + 1.6vw);
      line-height: 1.2;
      font-weight: bold;
      padding: 0.7em 0.56em;
      text-shadow: 0 1px 4px rgba(0,0,0,0.25);
      max-width: 90%;
    }
    .headerTitle {
      margin-top: -55vh;
      background: #ffffffbb;
    }
  `};
  .headerTitle b {
    background: #314755;
    background: -webkit-linear-gradient(to right, #ff0000, var(--accent-color),  #8a5a86);
    background: linear-gradient(to right,  #ff0000, var(--accent-color),  #8a5a86);
    /* background: -webkit-linear-gradient(to right, #314755, var(--accent-color),  #8a5a86);
    background: linear-gradient(to right,  #314755, var(--accent-color),  #8a5a86); */

    background-size: 100%;
    background-origin: 0;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ${MEDIA.PHONE`
    .pageHeader {
      font-size: 1.2em;
      line-height: 34px;
    }
    .headerImg {
      /*margin-top: -103px;*/
      /* min-height: 105vh; */
    }
    .parrafo {
      font-size: 1.2em;
      line-height: 1.3em;
      font-size: calc(1.1em + .2vw);
      line-height: calc(1.5em + .2vw);
      text-shadow: 0 1px 4px rgba(0,0,0,0.1);
      text-align: left;
    }
    .parrafoGrande {
      font-size: 1.4rem;
      font-size: calc(1.0975rem + 1.6vw);
      line-height: 1.2;
      font-weight: bold;
      padding: 0 1em;
      text-shadow: 0 1px 4px rgba(0,0,0,0.25);
      max-width: 100%;
      margin: 0em auto 2em;
    }
    .headerTitle {
      margin-top: 0;
      background: transparent;
      font-size: calc(1.375rem + 1.4vw);

      width: 100%;
      padding: 0em 0.7em;
      text-align: center;
      margin-bottom: 0.5em;
      border: 0;
      box-shadow: none;
    }
    .headerTitle b {
      background: #314755;
      background: -webkit-linear-gradient(to right, #ff0000, var(--accent-color),  #8a5a86);
      background: linear-gradient(to right,  #ff0000, var(--accent-color),  #8a5a86);
      background: -webkit-linear-gradient(to right, #314755, var(--accent-color),  #8a5a86);
      background: linear-gradient(to right,  #314755, var(--accent-color),  #8a5a86);

      background-size: 100%;
      background-origin: 0;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
      /* font-family: Calibri Bold,AvenirNext,Avenir,-apple-system,BlinkMacSystemFont,Roboto Slab,Droid Serif,Segoe UI,Oxygen-Sans,Ubuntu,Cantarell,Georgia,serif; */
    /* @media screen and (min-width: 768px) {
      .headerImg {
        min-height: 65vh;
        width: 100%;
      }
    } */
  `};
`;

// const AnimatedDiv = posed.div({
//   open: {
//     x: '0%',
//     opacity: 1,
//     delayChildren: 200,
//     staggerChildren: 150,
//   },
//   closed: { x: '0%', opacity: 0, delay: 300 },
// });
// const AnimatedItem = posed.div({
//   open: { x: '0%', opacity: 1 },
//   closed: { x: '40%', opacity: 0 },
// });

export default HeroSection;

import React, {
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Head from 'components/head';
import styled from 'styled-components';
import { Heading, Text, Flex } from '@chakra-ui/react';
import {
  accentTransparent,
  accentText,
} from '../constants/theme';
import HeroSection from '../components/heroSection';
import { graphql } from 'gatsby';
import Proyectos from '../components/proyectos';

export const query = graphql`query DesarrolloQuery {
  headerImg: file(relativePath: {eq: "images/servicios/desarrollo-web-apps.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
      resize(width: 1000, height: 630, quality: 80, cropFocus: ENTROPY, fit: INSIDE) {
        src
      }
    }
  }
  headerImgMovil: file(
    relativePath: {eq: "images/servicios/desarrollo-web-apps-movil.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 500, quality: 80, layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
    }
  }
}
`;

const Desarrollo = ({ data }) => (
  <Layout>
    <Head
      pageTitle=" 💻 Desarrollo Web y programación de Apps en méxico"
      imageUrl={
        'https://visualma.com' + data.headerImg.childImageSharp.resize.src
      }
    />
    <Content>
      <HeroSection
        header={true}
        headerImg={data.headerImg.childImageSharp.gatsbyImageData}
        headerImgMovil={data.headerImgMovil.childImageSharp.gatsbyImageData}
        background="#fff"
        title="Desarrollo"
        parrafoGrande={
          <Fragment>
            Desarrollo <b style={{ color: accentText }}>Web y Aplicaciónes</b>{' '}
            nativas
          </Fragment>
        }
        contentHeight="calc(100vh - 52px)"
        parrafoPequeno={
          <Fragment>
            Creamos soluciones integrales, funcionales e intuitivas que dan una
            presencia digital a empresas, organizaciones, productos y eventos.
          </Fragment>
        }
      />

      <Flex
        bg="#fff"
        className="contenido"
        p={['1em 0 4em', '0em 0em 7em']}
        justifyContent="flex-start"
        direction={['column', 'column', 'row']}
        borderTop={`2px solid ${accentTransparent}`}
      >
        <Flex justify="flex-start" direction="column" p="1em">
          <Heading as="h2" className="subHeader">
            Web Apps
          </Heading>
          <Text fontSize="xl" className="parrafoChico">
            Nuestras aplicaciones web tienen las mismas capacidades y desempeño
            que un app nativo.
            <br />
            <br />
            Creando asi experiencias accesibles a un mayor numero de usuarios
            atravez de un navegador de internet en cualquier tipo de
            dispositivo.
          </Text>
        </Flex>

        <Flex
          bg={[accentTransparent, accentTransparent, 'transparent']}
          justify="flex-start"
          direction="column"
          p="1em"
          borderRadius={[0, 0, '12px', '12px']}
          boxShadow={['none', 'none', '2px 2px 11px 1px #afafaf33']}
          borderLeft={[0, 0, '1px solid #7e969e']}
          borderRight={[0, 0, '1px solid #7e969e']}
        >
          <Heading as="h2" className="subHeader">
            <br />
          </Heading>
          <Text fontSize="xl" className="parrafoChico">
            <br />
            Utilizamos las ultimas tecnologías para crear páginas web,
            aplicaciones, show rooms, instalaciones interactivas, presentaciones
            audio-visuales y juegos memorables.
          </Text>
        </Flex>

        <Flex justify="flex-start" direction="column" p="1em">
          <Heading as="h2" className="subHeader">
            Apps Nativas
          </Heading>
          <Text fontSize="xl" className="parrafoChico">
            Llevamos tu empresa a todos los dispositivos móviles y a cualquier
            sistema operativo.
            <br />
            <br />
            IOS, Android, Windows, Mac OSX y Linux
          </Text>
        </Flex>
      </Flex>

      <Proyectos
        title="Nuestros proyectos de Programación"
        category="Aplicaciones y Videojuegos"
        limit={3}
      />
    </Content>
  </Layout>
);
Desarrollo.propTypes = {
  data: PropTypes.object.isRequired,
};
const Content = styled.div``;

export default Desarrollo;
